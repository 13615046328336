import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { inject } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { StorageKeys } from "../models/storage.interface";

export const authGaurd: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) => {

     const authService = inject(AuthService);
     const router = inject(Router);
     const localStorage = inject(LocalStorageService);
        if( authService.isAuthorized()){
          return true
        }else {
          localStorage.set(StorageKeys.redirectionURL , state.url);
          return router.navigate(["/auth/sign-in"])
        }

  };

